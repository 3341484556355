export default function SgExpanderLogic () {
  const el = document.querySelector('.SgStyleguide__root');
  if(!el) return;

  el.querySelectorAll('.SgStyleguide__toggleTrigger').forEach((trigger) => {
    const parent = trigger.parentNode;
    const target = parent.querySelector('.SgStyleguide__toggleTarget');
    const expander = trigger.querySelector('.SgExpander');

    if (trigger.classList.contains('SgStyleguide__toggleTrigger--reverse')) {
      if (!parent.classList.contains('SgFileIndex')) {
        trigger.classList.add('is-expanded');
        expander.classList.add('is-expanded');
        target.classList.add('is-expanded');
      }
    }

    trigger.addEventListener('click', () => {
      trigger.classList.toggle('is-expanded');
      expander.classList.toggle('is-expanded');
      target.classList.toggle('is-expanded');
    });
  });

};
