const ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN = /^[a-zA-Z0-9@'\/\-_.+?\s]+$/;
const ALPHANUMERIC_PATTERN = '[a-zA-Z0-9]*';

const prepareRegexForHtmlPattern = (regex = '') => String(regex).substring(1, String(regex).length - 1);

const VALIDATION_FIELD_USERNAME = 'username';
const VALIDATION_FIELD_EMAIL = 'email';
const VALIDATION_FIELD_PASSWORD = 'password';
const VALIDATION_FIELD_FIRSTNAME = 'firstname';
const VALIDATION_FIELD_SURNAME = 'surname';
const VALIDATION_FIELD_DOB = 'dob';
const VALIDATION_FIELD_SSN = 'ssn';
const VALIDATION_FIELD_GHP_ID = 'ghpID';
const VALIDATION_FIELD_MRN = 'mrn';
const VALIDATION_FIELD_MRN_LONG = 'mrnLong';
const VALIDATION_FIELD_P_RXN = 'pRXN';
const VALIDATION_FIELD_ADRESS = 'addressLine';
const VALIDATION_FIELD_CITY = 'city';
const VALIDATION_FIELD_STATE = 'state';
const VALIDATION_FIELD_ZIP = 'zip';
const VALIDATION_FIELD_SECURITY_QUESTION = 'securityQuestion';
const VALIDATION_FIELD_CONFIRMATION_CODE = 'confirmationCode';
const VALIDATION_FIELD_PHONE = 'phone';

const FORMS_VALIDATION_FIELDS_IDS = {
  VALIDATION_FIELD_USERNAME,
  VALIDATION_FIELD_EMAIL,
  VALIDATION_FIELD_PASSWORD,
  VALIDATION_FIELD_FIRSTNAME,
  VALIDATION_FIELD_SURNAME,
  VALIDATION_FIELD_DOB,
  VALIDATION_FIELD_SSN,
  VALIDATION_FIELD_GHP_ID,
  VALIDATION_FIELD_MRN,
  VALIDATION_FIELD_MRN_LONG,
  VALIDATION_FIELD_P_RXN,
  VALIDATION_FIELD_ADRESS,
  VALIDATION_FIELD_CITY,
  VALIDATION_FIELD_STATE,
  VALIDATION_FIELD_ZIP,
  VALIDATION_FIELD_SECURITY_QUESTION,
  VALIDATION_FIELD_CONFIRMATION_CODE,
  VALIDATION_FIELD_PHONE
};

export default {
  [VALIDATION_FIELD_USERNAME]: {
    min: 3,
    max: 20,
    pattern: ALPHANUMERIC_PATTERN,
    required: true,
  },
  [VALIDATION_FIELD_EMAIL]: {
    min: null,
    max: null,
    pattern: prepareRegexForHtmlPattern(ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN),
    required: true,
  },
  [VALIDATION_FIELD_PASSWORD]: {
    min: 8,
    max: null,
    pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\S]{8,}$',
    required: true,
  },
  [VALIDATION_FIELD_FIRSTNAME]: {
    min: 1,
    max: 20,
    pattern: prepareRegexForHtmlPattern(ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN),
    required: true,
  },
  [VALIDATION_FIELD_SURNAME]: {
    min: 1,
    max: 20,
    pattern: prepareRegexForHtmlPattern(ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN),
    required: true,
  },
  [VALIDATION_FIELD_DOB]: {
    min: null,
    max: null,
    pattern: '.{10,}',
    required: false,
  },
  [VALIDATION_FIELD_SSN]: {
    min: 4,
    max: 4,
    pattern: '.{4,}',
    required: true,
  },
  [VALIDATION_FIELD_GHP_ID]: {
    min: 11,
    max: 11,
    pattern: '.{11,}',
    required: true,
  },
  [VALIDATION_FIELD_MRN]: {
    min: 7,
    max: 7,
    pattern: '.{7,}',
    required: true,
  },
  [VALIDATION_FIELD_MRN_LONG]: {
    min: 5,
    max: 10,
    pattern: '[0-9]{5,10}',
    required: true,
  },
  [VALIDATION_FIELD_P_RXN]: {
    min: 7,
    max: 7,
    pattern: '.{7,}',
    required: true,
  },
  [VALIDATION_FIELD_ADRESS]: {
    min: 1,
    max: 50,
    pattern: prepareRegexForHtmlPattern(ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN),
    required: false,
  },
  [VALIDATION_FIELD_CITY]: {
    min: 1,
    max: 20,
    pattern: prepareRegexForHtmlPattern(ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN),
    required: false,
  },
  [VALIDATION_FIELD_STATE]: {
    min: 2,
    max: 2,
    pattern: null,
    required: false,
  },
  [VALIDATION_FIELD_ZIP]: {
    min: 5,
    max: 5,
    pattern: '.{5,}',
    required: true,
  },
  [VALIDATION_FIELD_SECURITY_QUESTION]: {
    min: 1,
    max: 50,
    pattern: prepareRegexForHtmlPattern(ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN),
    required: false,
  },
  [VALIDATION_FIELD_CONFIRMATION_CODE]: {
    min: 6,
    max: 6,
    pattern: '.{6,}',
    required: true,
  },
  [VALIDATION_FIELD_PHONE]: {
    min: 12,
    max: 12,
    pattern: '.{12,}',
    required: true,
  },
};

export {
  ALPHANUMERIC_WITH_SPECIAL_CHARS_PATTERN,
  ALPHANUMERIC_PATTERN,
  FORMS_VALIDATION_FIELDS_IDS,
  prepareRegexForHtmlPattern
};
