const PHONE_VALIDATION_ID = 'phone';
const DATE_VALIDATION_ID = 'date';
const SSN_VALIDATION_ID = 'ssn';
const GHP_ID_VALIDATION_ID = 'ghpID';
const ZIP_VALIDATION_ID = 'zip';
const CONFIRMATION_CODE_VALIDATION_ID = 'confirmationCode';
const PRXN_VALIDATION_ID = 'pRXN';
const MRN_VALIDATION_ID = 'mrn';
const MRN_LONG_VALIDATION_ID = 'mrnLong';

const maskList = {
  PHONE_VALIDATION_ID,
  DATE_VALIDATION_ID,
  SSN_VALIDATION_ID,
  GHP_ID_VALIDATION_ID,
  ZIP_VALIDATION_ID,
  CONFIRMATION_CODE_VALIDATION_ID,
  PRXN_VALIDATION_ID,
  MRN_VALIDATION_ID,
  MRN_LONG_VALIDATION_ID
};

module.exports = maskList;
