export const pascalToSpaced = (text) => {
  if (typeof text !== 'string') return false;
  return text.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
};

export const toUpperCase = (text) => {
  if (typeof text !== 'string') return false;
  return text.replace(/\w/, (char) => char.toUpperCase());
};

export const generatePageName = (text) => {
  if (typeof text !== 'string') return false;
  const regex = new RegExp(/([A-Z][a-z]+) \/ ([A-Z][a-z]+) ([A-Z][a-z]+)( [A-Z][a-z]+)?/);
  if (regex.test(text)) return text;
  return pascalToSpaced(text).split('/').map(((str) => toUpperCase(str))).join(' / ');
};

export const trimWhiteSpaces = (text = '') => ((typeof text === 'string') ? text.replace(/\s/g, '') : text);

export const isSetWindowVariable = (typeof window !== 'undefined');
