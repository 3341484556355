const getFileNameFromPath = (path) => {
  const folders = path.split('/');
  const nameAndType = folders[folders.length - 1];
  const name = nameAndType.slice(0, nameAndType.indexOf('.'));
  return name;
};

/* @description
To reduce double responses related to svg injection on the Sitecore and StyleGuide side,
a validation has been added that allows the request to be executed only once.
Only in case if the id of the container containing the svg does not exist in the listOfLoadedSprites global variable.
*/
const loadedSVGlist = {
  init: () => {
    if (!Array.isArray(window.listOfLoadedSprites)) { window.listOfLoadedSprites = []; }
  },
  add: (name) => {
    if (Array.isArray(window.listOfLoadedSprites)) {
      window.listOfLoadedSprites.push(name);
    }
  },
  contains: (name) => {
    if (Array.isArray(window.listOfLoadedSprites)) {
      return window.listOfLoadedSprites.includes(name);
    }
    return false;
  }
};

function getIconSet(url) {
  const id = getFileNameFromPath(url);
  loadedSVGlist.init();

  if (!loadedSVGlist.contains(id)) {
    loadedSVGlist.add(id);
    let ajax = new XMLHttpRequest();

    if (typeof XDomainRequest !== 'undefined') {
      ajax = new XDomainRequest();
    }

    ajax.open('GET', url, true);
    ajax.onprogress = () => {};
    ajax.onload = () => {
      if (!ajax.responseText || ajax.responseText.substr(0, 4) !== '<svg') {
        throw Error('Invalid SVG Response');
      }
      if (ajax.status < 200 || ajax.status >= 300) {
        return;
      }

      const div = document.createElement('div');
      div.id = `js-ajax-${id}`;
      div.classList.add('u-display--none');
      div.innerHTML = ajax.responseText;

      document.body.insertBefore(div, document.body.childNodes[0]);
    };
    ajax.send();
  }
}

module.exports = getIconSet;
