const Cleave = require('cleave.js').default;
const masks = require('./maskList');

function FieldMask(props) {
  if (!props.el) {
    return;
  }

  const self = props.el;
  const maskType = self.getAttribute('data-mask') || false;
  const maxValues = {
    ssn: 4, ghpID: 11, zip: 5, confirmationCode: 6, pRXN: 7, mrn: 7, mrnLong: 10
  };

  function phoneCleave() {
    return new Cleave(self, {
      numericOnly: true,
      blocks: [3, 3, 4],
      delimiters: ['-', '-', '-']
    });
  }

  function numCleave() {
    return new Cleave(self, {
      numericOnly: true,
      blocks: [maxValues[maskType]]
    });
  }

  function handlePasteEventDateCleave() {
    const getDateInputNodeList = document.querySelectorAll('[data-mask="date"]');
    const dateInput = getDateInputNodeList && Array.from(getDateInputNodeList)?.[0];

    // Check whether the string matches the regex -> "YYYY-MM-DD",
    const isPastedDateInSpecyficFormat = (date) => /^\d{4}-\d{2}-\d{2}$/.test(date);

    return dateInput && dateInput.addEventListener('paste', (event) => {
      const pastedData = event.clipboardData.getData('text');
      if (isPastedDateInSpecyficFormat(pastedData)) {
        const dateParts = pastedData.split('-');

        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];

        const formattedDate = `${month}/${day}/${year}`;
        dateInput.value = formattedDate;
      }
    });
  }

  function dateCleave() {
    handlePasteEventDateCleave();
    return new Cleave(self, {
      date: true,
      datePattern: ['m', 'd', 'Y']
    });
  }

  function validation(mask) {
    const rules = {
      [masks.PHONE_VALIDATION_ID]: () => phoneCleave(),
      [masks.DATE_VALIDATION_ID]: () => dateCleave(),
      [masks.SSN_VALIDATION_ID]: () => numCleave(),
      [masks.GHP_ID_VALIDATION_ID]: () => numCleave(),
      [masks.ZIP_VALIDATION_ID]: () => numCleave(),
      [masks.CONFIRMATION_CODE_VALIDATION_ID]: () => numCleave(),
      [masks.PRXN_VALIDATION_ID]: () => numCleave(),
      [masks.MRN_VALIDATION_ID]: () => numCleave(),
      [masks.MRN_LONG_VALIDATION_ID]: () => numCleave()
    };
    return (rules?.[mask]
      ? rules?.[mask]()
      : null); // do nothing if unsupported mask type provided
  }

  // only proceed if a mask type is provided
  if (maskType) {
    validation(maskType);
  }
}

module.exports = FieldMask;
